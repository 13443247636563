*{
    margin: 0;
    padding: 0;
}

.container-background-modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;
    background-color: rgba(119, 119, 120, 0.669);
  }

.container-modal{
    width: 95%;
    height: 12rem;
    background-position: -110px 70px;
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: #000000;
}

.title-modal{
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    font-size: 28px;
    font-weight: bolder;
}

.container-btns-modal{
    display: flex;
    justify-content: space-evenly;
    border-right: solid;
}

.btn-container{
    height: 5rem;
    border-left: solid;
    border-color: white;
    padding: 0 2em 0 5em;
}

.btn-modal{
    width: 10rem;
    height: 2.8rem;
    background-color: #00e8f9;
    border: none;
    color: black;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 1rem;
    margin-right: 1em;
    cursor: pointer;
    border-radius: 17px 17px 17px 17px;
}

.title-modal{
    width: 100%;
    display: block;
    font-size: 1.4em;
    padding-left: 1em;
    color: white;
}

.information-modal{
    font-size: 1.1em;
    padding:0 2.5em 0 1.5em;
    color: white;
}

.container-second-modal{
    display: flex;
    align-items: center;
    height: 100%;
} 

.title-second-modal{
    font-size: 20px;
    font-weight: bolder;
    margin: 0 2rem;   
    display: flex;
    align-items: center;
}

@media (max-width: 600px){
    .container-modal{
        width: 90%;
        height: 14rem;
        margin:0 auto;
        background-position: -130px 40px;
    }
    .title-modal{
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        font-size: 22px;
        font-weight: bolder;
    }
    .btn-modal{
        width: 5.4rem;
        height: 2rem;
        margin-top: 1.4rem;
        cursor: pointer;
    }
    .information-modal{
        font-size: 10px;
        margin: 1.4rem;
    }
  }