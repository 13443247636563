.video-wrapper{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.vjs-icon-placeholder{
  cursor: pointer;
}

.vjs-big-play-button{
  display: none !important;
}

.play-button{
      z-index: 1000;
    font-size: 3em;
    height: 2.5em;
    width: 5em;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0;
    margin-top: -0.81666em;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 0.3em;
    transition: all 0.4s;
    cursor: pointer;
}

.play-button-icon{
    cursor: pointer;
}

.play-button-icon svg{
    width: auto;
    height: 100%;
    fill: white;
}
/*.vjs-big-play-button {*/
/*  display: none !important;*/
/*}*/

.video-container{
  position: absolute;
  height: inherit;
  width: 100%;
  z-index: 1;
}

.video-rescaled-container{
  position: relative;
}

.video-inner-wrapper{
  width: 100%;
  height: inherit;
}

p{
  margin-top: 0;
  margin-bottom: 1rem;
}